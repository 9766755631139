.sf-canteen-user-page {
  color: #707070;
}

.sf-canteen-user-tabs {
  margin-top: 20px;
  margin-bottom: 20px;
}

.sf-canteen-user-tabs > button {
  border: none;
  background: none;
  font-size: 1.55em;
  font-weight: 300;
  color: #707070;
  padding-left: 0;
  margin-right: 3.5%;
  margin-bottom: 20px;
}

.sf-canteen-user-tabs > button {
  cursor: pointer;
}

.sf-canteen-user-tabs > button.sf-canteen-user-tab-active {
  color: #51bc51;
  border-bottom: 3px solid #51bc51;
  font-weight: 600;
}

.sf-canteen-user-meals-date {
  margin-bottom: 4em;
}
