.sf-canteen-user-topbar {
  position: fixed;
  top: 0;
  height: 100%;
  width: 70px;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1.5em;
  background: #094249;
  color: white;
}

.sf-canteen-user-topbar-logged-out > .sf-canteen-user-topbar {
  width: 40%;
  padding: 0;
}

.sf-canteen-user-topbar-logged-out .sf-canteen-user-topbar-buttons {
  display: none;
}

.sf-canteen-user-topbar-buttons > button.ui.button {
  margin-top: 25px;
}

.sf-canteen-user-topbar-logged-out .sf-canteen-user-topbar-img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: url("https://studierendenfutter.de/wp-content/uploads/2021/05/mein.studierendenfutter-Login-Medium.jpg");
  background-position: center;
  background-size: cover;
}

.sf-canteen-user-topbar-logged-in .sf-canteen-user-topbar-img {
  display: none;
}

.sf-canteen-user-topbar-logged-out > .sf-canteen-user-content {
  margin-left: 40%;
}

.sf-canteen-user-content {
  margin-left: 80px;
  padding: 1em;
}

.sf-canteen-user-topbar-heading {
  color: white;
  text-decoration: none;
}

.sf-canteen-user-topbar-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.sf-canteen-user-topbar-button-active > i.icon {
  color: #094249 !important;
}

.sf-canteen-user-topbar-button > i.icon {
  color: #fffcdc !important;
}

.sf-canteen-user-topbar-button-active {
  background: #fffcdc !important;
}

.sf-canteen-user-topbar-button {
  background: #094249 !important;
}

.sf-canteen-user-top-area {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.sf-canteen-user-topbar-logged-out .sf-canteen-user-top-area {
  justify-content: center;
}

@media (max-width: 1000px) {
  .sf-canteen-user-topbar-logged-out .sf-canteen-user-topbar {
    display: none;
  }

  .sf-canteen-user-topbar-logged-out .sf-canteen-user-content,
  .sf-canteen-user-topbar-logged-in .sf-canteen-user-content {
    margin-left: 0;
    margin-bottom: 70px;
  }

  .sf-canteen-user-topbar-logged-in .sf-canteen-user-topbar {
    width: 100%;
    height: 70px;
    left: 0;
    bottom: 0;
    top: inherit;
    align-items: center;
    flex-direction: row;
    padding: 5px;
  }

  .sf-canteen-user-topbar-logged-in
    .sf-canteen-user-topbar
    .sf-canteen-user-topbar-buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }

  .sf-canteen-user-topbar-button {
    margin-bottom: 0;
  }

  .sf-canteen-user-topbar-buttons > button.ui.button {
    margin-top: 0px;
  }
}
